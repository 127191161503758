import { ProductWithAmount } from 'src/redux/modals/types';
import { RootState } from '../../../reducers';
import { placeProductToClientCart } from '../../clientCart/operations/placeProductToClientCart';
import { placeProductToServerCart } from '../../serverCart/operations/placeProductToServerCart';

export const placeProductToCart = (
  product: ProductWithAmount,
  place: string,
  onPlaceProductToCart: (product: ProductWithAmount) => void,
) => async (dispatch, getState: () => RootState) => {
  const { cartType } = getState().app;

  const { amount, ...rest } = product;
  if (cartType === null) {
    return;
  }

  if (cartType === 'client')
    dispatch(placeProductToClientCart(
    rest.ean, amount,
    { place, product: rest },
    onPlaceProductToCart,
));

  if (cartType === 'server') {
    dispatch(placeProductToServerCart(
      rest.ean, amount,
      { place, product: rest },
      onPlaceProductToCart,
    ));
  }

  // ======== Analytics logic ========== start
  /**
   * Do not place Analytics logic here.
   * Because dispatch results are unpredictable.
   */
  // ======== Analytics logic ========== end
};
