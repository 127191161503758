import { useEffect } from "react";
import useLocalization from 'src/services/localization/useLocalization';
import { sendFeatureNotificationButtonClick, sendFeatureNotificationClosed, sendFeatureNotificationShown } from "../helpers/analytics";
import { useFeatureNotificationClosing } from "./useFeatureNotificationClosing";
import { useRouter } from "next/router";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";

export const notificationFeatureKey = 'favorites';

interface NotificationFeatureProps {
  title: string;
  description: string;
  iconSrc: string;
  onClick(): void;
  onClose(): void;
}

export const useFeatureNotificationProps = () => {
  const localize = useLocalization();
  const { isOpen, closeNotification } = useFeatureNotificationClosing();
  const { push } = useRouter();
  const { fetchConfig: { language } } = useAppConfig();

  useEffect(() => {
    if (isOpen) {
      sendFeatureNotificationShown(notificationFeatureKey);
    }
  }, [isOpen]);

  const handleClick = () => {
    sendFeatureNotificationButtonClick(notificationFeatureKey);
    push(`/${language}/lists/`);
    closeNotification();
  };

  const handleCloseClick = () => {
    sendFeatureNotificationClosed(notificationFeatureKey);
    closeNotification();
  };

  const notificationFeatureProps: NotificationFeatureProps = {
    title: localize('favorites_feature_title'),
    description: localize('favorites_feature_description'),
    onClick: handleClick,
    onClose: handleCloseClick,
    iconSrc: '/i/heart.gif',
  };

  return notificationFeatureProps;
};
