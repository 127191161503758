import { NotificationOfGamification } from "src/modules/gamifications/FixedDeliveryDiscount";
import NotificationFeature from "../NotificationFeature/NotificationFeature";
import { useFeatureNotificationClosing } from "../../hooks/useFeatureNotificationClosing";


const GamificationOrFeatureNotification = () => {
  const { isOpen } = useFeatureNotificationClosing();

  if (isOpen) {
    return <NotificationFeature />;
  }

  return <NotificationOfGamification />;
};

export default GamificationOrFeatureNotification;
