import Button from 'src/components/ui-kit/Button/Button';
import WithFadeIn from 'src/components/views/WithFadeIn/WithFadeIn';
import useLocalization from 'src/services/localization/useLocalization';
import { styles } from './NotificationFeature.styles';
import { useFeatureNotificationProps } from '../../hooks/useFeatureNotificationsProps';

export default function NotificationFeature() {
  const {
    title,
    description,
    onClick,
    onClose,
    iconSrc,
  } = useFeatureNotificationProps();
  const localize = useLocalization();

  return (
    <WithFadeIn>
      <div
        role="alert"
        data-marker="Notification feature"
        className='NotificationFeature'
      >
        <div className="NotificationFeature__imgWrapper">
          <img
            src={iconSrc}
            alt='feature icon'
            className='NotificationFeature__icon'
          />
        </div>

        <div className="NotificationFeature__content">
          <div className="NotificationFeature__title">
            {title}
          </div>
          <div className="NotificationFeature__description">
            {description}
          </div>
          <div className="NotificationFeature__button">
            <Button
              variant='ghost'
              onClick={onClick}
              dataMarkerValue='show'
            >
              {localize('general.view')}
            </Button>
          </div>
        </div>

        <button
          className='NotificationFeature__close icon-close'
          data-marker='close'
          onClick={onClose}
          data-testid='close'
        />
        <style jsx>{styles}</style>
      </div>
    </WithFadeIn>
  );
}
