import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useNearestOrder } from "src/components/containers/NearestActiveOrder/NearestActiveOrderHelpers";
import { useFeatureNotificationClosing } from "src/modules/notifications/NewFeatureNotification";
import { Product } from "src/redux/apiTypes";
import { selectCartItems } from "src/redux/cart/general/cartSelectors";
import { useAppSelector } from "src/redux/hooks";
import { openModal } from "src/redux/modals";
import { ProductWithAmount } from "src/redux/modals/types";
import useUserProfile from "src/swr/useUserProfile";
import { sendAddToProductModalWasOpened } from "src/utils/marketing/enhancedEcommerce";

const useAddProductTo = () => {
  const userPhone = useUserProfile().userProfileData?.loginPhone || null;
  const { nearestOrder } = useNearestOrder(userPhone);
  const dispatch = useDispatch();
  const cartItems = useAppSelector(selectCartItems);
  const { closeNotification } = useFeatureNotificationClosing();

  const addToModalsWasShown = Cookies.get('addToModalWasShownEarlier') === 'true';
  const cartContainsProducts = cartItems.length > 0;
  const userHaveActiveOrder = nearestOrder && nearestOrder.is_editable;

  const addProductTo = (onAddToCart: () => void, product: (Product | ProductWithAmount) = null) => {
    closeNotification();

    if (!cartContainsProducts && !addToModalsWasShown && userHaveActiveOrder) {
      dispatch(openModal('addToModal', { onAddToCart, orderId: nearestOrder.id, product }));
      sendAddToProductModalWasOpened();
    } else {
      onAddToCart();
    }
  };

  return addProductTo;
};

export default useAddProductTo;
