export const sendFeatureNotificationShown = (feature: string) => {
  window.dataLayer.push({
    event: "new_feature_popup_shown",
    feature,
  });
};

export const sendFeatureNotificationButtonClick = (feature: string) => {
  window.dataLayer.push({
    event: "new_feature_popup_click",
    feature,
  });
};

export const sendFeatureNotificationClosed = (feature: string) => {
  window.dataLayer.push({
    event: "new_feature_popup_closed",
    feature,
  });
};
