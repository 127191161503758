import { useServices } from "..";

const useFavorites = () => {
  const services = useServices();

  if (!services) {
    throw new Error('ServicesContext is used without provider in useFavorites');
  }

  return services.favorites;
};

export default useFavorites;
