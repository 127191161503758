import { Dispatch } from 'redux';
import { NotificationService } from 'src/services/notification/types';
import { Product } from '../../../apiTypes';
import changeCartItemAmount from './changeCartItemAmount';
import removeCartItem from './removeCartItem';

const updateAmount = (
  newAmount: number, place: string, product: Product,
  openTextNotification: NotificationService['openTextNotification'],

) => (dispatch: Dispatch) => {
  if (newAmount === 0) {
    openTextNotification('product_removed');
    dispatch(removeCartItem(product, place));
    return;
  }

  dispatch(changeCartItemAmount(product, newAmount, place));
  openTextNotification('product_amount_changed');
};

export default updateAmount;
