import useToggles from "src/services/toggles/useToggles";
import { useLocalStorage } from "usehooks-ts";
import { notificationFeatureKey } from "./useFeatureNotificationsProps";

export const useFeatureNotificationClosing = () => {
  const { FEATURE_NOTIFICATION_AVAILABLE } = useToggles();
  const [wasClosed, setWasClosed] = useLocalStorage( `${notificationFeatureKey}_closed`, false);
  const isOpen = !wasClosed && FEATURE_NOTIFICATION_AVAILABLE;

  const closeNotification = () => {
    if(isOpen) {
      setWasClosed(true);
    }
  };

  return {
    isOpen,
    closeNotification,
  };
};
