import { useRouter } from "next/router";
import { Product } from "src/redux/apiTypes";
import useFavorites from "src/services/favorites/useFavorites";
import { addToCartButtonsEnum } from "src/utils/marketing/enhancedEcommerce";

const useCartProductTileFavoriteProps = (place: string, product: Product) => {
  const { query: { id: currentListId } } = useRouter();
  const {
    toggleFavorite,
    getProductIsFavorite,
    favoriteEansMap,
  } = useFavorites();

  const ean = product.ean;
  const isFavoritePage = place === addToCartButtonsEnum.FAVORITES && currentListId;
  const isFavorite = isFavoritePage
    ? !!favoriteEansMap[ean]?.includes(currentListId)
    : getProductIsFavorite(ean);

  const handleFavoriteClick = () => {
    toggleFavorite({
      product,
      listId: currentListId,
    });
  };

  return {
    isFavorite,
    handleFavoriteClick,
  };
};

export default useCartProductTileFavoriteProps;
