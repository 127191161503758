import { phone_sm } from "src/styles/media";
import typography from "src/styles/typography";
import css from "styled-jsx/css";

export const styles = css`
  .NotificationFeature {
    width: 339px;
    background-color: white;
    display: flex;
    padding: 12px 22px 12px 8px;
    box-shadow: 0px 8px 12px 0px rgba(0,0,0,.24);
    pointer-events: all;
  }

  .NotificationFeature__imgWrapper {
    flex-shrink: 0;
    width: 56px;
    height: 56px;
  }

  .NotificationFeature__content {
    margin-left: 8px;
  }

  .NotificationFeature__title {
    ${typography.h6}
    margin-bottom: 4px;
  }

  .NotificationFeature__description {
    ${typography.text}
    margin-bottom: 2px;
  }

  .NotificationFeature__close {
    margin: 0;
    outline: none;
    border: none;
    background: none;

    pointer-events: all;
    color: var(--colors-brandLightGrey8);
    font-size: 11px;
    padding: 5px;
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
  }

  .NotificationFeature__icon {
    width: 100%;
    height: auto;
  }

  @media ${phone_sm} {
    .NotificationFeature {
      width: 304px;
      padding: 8px 22px 8px 8px;
    }

    .NotificationFeature__imgWrapper {
      width: 44px;
      height: 44px;
    }
  }
`;
