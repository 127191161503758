import { useServices } from "..";

const useNotification = () => {
  const services = useServices();

  if (!services) {
    throw new Error('ServicesContext is used without provider in useNotification');
  }

  return services.notification;
};

export default useNotification;
